<template>
  <div>
    <div class="flex justify-space-between">
      <div class="box self-flex-end"><h2 class="mv-0">USERS</h2></div>
      <div class="box"><el-button @click="$onCommandParams('settings')">BACK</el-button></div>
    </div>
    <el-divider class="mv-10 o-020"></el-divider>
    <el-table
      :data="dataUsers"
      style="width: 100%">
      <el-table-column
        label="Name">
            <template #default="scope">
              <el-input size="small"
                style="text-align:center"
                v-model="scope.row.name"></el-input>
            </template>
      </el-table-column>
      <el-table-column
        label="Role">
            <template #default="scope">
                <el-radio-group v-model="scope.row.role" size="small">
                    <el-radio-button :label="r" v-for="(r, i) in roles" :key="i"></el-radio-button>
                </el-radio-group>
            </template>
      </el-table-column>
      <el-table-column
        label="Password">
            <template #default="scope">
              <el-input size="small"
                style="text-align:center"
                v-model="scope.row.password"></el-input>
            </template>
      </el-table-column>
      <el-table-column>
        <template slot-scope="scope">

            <div v-if="scope.$index >= users.length">
                <el-button
                 @click.native.prevent="createUser(scope.row)" type="text" size="small">
                    Create
                </el-button>
            </div>
            <div class="flex gaps" v-else>
                <el-button @click.native.prevent="saveRow(scope.row)" type="text" size="small">
                    Save
                </el-button>
                <el-button @click.native.prevent="deleteRow(scope.row, scope.$index)" type="text" size="small">
                    Delete
                </el-button>
            </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="flex justify-flex-end m-10">
        <el-button @click="addRow()" size="small">
            Add User
        </el-button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Users',

  data () {
    return {
      dataUsers: []
    }
  },

  computed: {
    ...mapState({
      users: state => state.user.users,
      roles: state => state.user.roles
    })
  },

  methods: {
    deleteRow (row, index) {
      this.$store.dispatch('removeUser', row._id)
        .then(() => {
          this.dataUsers.splice(index, 1)
        })
        .then(() => {
          this.$message({
            showClose: true,
            message: 'User remove successfully',
            type: 'success'
          })
        })
    },
    saveRow (row) {
      if (row.password !== '' && row.password.length < 6) {
        this.$message({
          showClose: true,
          message: 'The length of the new password must be at least 6 characters',
          type: 'error'
        })
      } else {
        this.$store.dispatch('editUser', {
          id: row._id,
          content: row
        })
          .then(() => {
            this.$message({
              showClose: true,
              message: 'User edited successfully',
              type: 'success'
            })
          })
      }
    },
    createUser (payload) {
      if (payload.hasOwnProperty('password') && payload.password !== '' && payload.password.length < 6) {
        this.$message({
          showClose: true,
          message: 'The length of the password must be at least 6 characters',
          type: 'error'
        })
      } else {
        this.$store.dispatch('createUser', payload)
          .then(() => {
            this.$message({
              showClose: true,
              message: 'New user created successfully',
              type: 'success'
            })
          })
      }
    },
    addRow () {
      let newRow = {
        name: '',
        role: 'user',
        password: ''
      }
      this.dataUsers = [...this.dataUsers, newRow]
    }
  },
  mounted () {
    this.dataUsers = this.users.map((x) => Object.assign({}, x, { password: '' }))
    // Object.assign(this.dataUsers, this.users, { password: '' })
  }
}
</script>

<style lang="scss">
</style>
